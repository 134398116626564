<template>
  <div class="create-new-sign">
    <div class="create-new-sign-form slide-in-top">
        <div class="create-new-sign-form-header">
            <h2 v-if="!edit_item">יצירת שלט חדש</h2>
            <h2 v-if="edit_item">עריכת שלט קיים</h2>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="create-new-sign-form-content">
            <div class="create-new-sign-form-content-field">
                <h2>ברקוד:</h2>
                <el-input v-model="values.barcode" placeholder="ברקוד" :disabled="edit_item?true:false" />
            </div>
            <div class="create-new-sign-form-content-field">
                <h2>שם פריט בעברית</h2>
                <el-input v-model="values.he_name" placeholder="שם בעברית" maxlength="35" />
            </div>
            <div class="create-new-sign-form-content-field">
                <h2>שם פריט בלועזית</h2>
                <el-input v-model="values.ru_name" placeholder="שם בלועזית" maxlength="38" />
            </div>
            <div class="create-new-sign-form-content-field">
                <h2>יחידת מידה</h2>
                <el-select style="width:100%;" v-model="values.size" class="m-2" placeholder="בירת יחידת מידה לפריט" size="large">
                    <el-option
                    v-for="size in size_options"
                    :key="size"
                    :value="size"
                    />
                </el-select>
            </div>
            <div v-if="values.size=='יח'" class="create-new-sign-form-content-field">
                <h2>נפח הפריט (בגרמים)</h2>
                <el-input type="number" style="width:100%;" v-model="values.volume" placeholder="נפח" />
            </div>
            <div class="create-new-sign-form-content-field">
                <h2>מחלקה</h2>
                <el-select style="width:100%;" v-model="values.department" class="m-2" placeholder="בירת יחידת מידה לפריט" size="large">
                    <el-option
                    v-for="department in departments"
                    :key="department.uid"
                    :value="department.name"
                    />
                </el-select>
            </div>
            <div class="create-new-sign-form-content-field">
                <h2>מחיר מחירון</h2>
                <el-input type="number" style="width:100%;" v-model="values.price" placeholder="מחיר מחירון" :min="1" :step="0.01" :max="1000" />
            </div>
            <div class="create-new-sign-form-content-field">
                <el-button v-if="!edit_item" @click="handle_submit" style="width:100%;" type="success">הוספה</el-button>
                <el-button v-if="edit_item" @click="handle_submit_edit" style="width:100%;" type="warning">עדכון</el-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../../store'
import { computed } from '@vue/runtime-core'
import {slide_pop_error,alert} from '../../../Methods/Msgs'
import {check_if_item_exist,create_new_sign,get_all_departments} from './Scripts/scripts'

export default {
    props:['edit_item'],
    emits:['finish','finish_edit','close'],
    setup(props,{emit}){
        const is_pending = ref(false)
        const err_msg = ref('')
        const departments = ref([])
    

        const size_options = ref([
            'יח',
            'ק"ג'
        ])
        const user = ref(computed(()=>{
            return store.state.user
        }))


        const values = ref({
            date_added: new Date(),
            date_updated :new Date(),
            user_update: user.value.uid,
            barcode:'',
            he_name:'',
            ru_name:'',
            size:'',
            department:'',
            volume:'',
            price:'',
            first_number:'1',
            second_number:'',
        })

        const validation = () => {
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'barcode':
                        if(!value){
                            err_msg.value = 'עליך להזין ברקוד!'
                            return false
                        }
                        break;
                    case 'he_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם בעברית!'
                            return false
                        }
                        break;
                    // case 'ru_name':
                    //     if(!value){
                    //         err_msg.value = 'עליך להזין שם לועזי!'
                    //         return false
                    //     }
                    //     break;
                    case 'size':
                        if(!value){
                            err_msg.value = 'עליך לבחור יחידת מידה לפריט!'
                            return false
                        }
                        break;
                    case 'department':
                        if(!value){
                            err_msg.value = 'עליך לבחור מחלקה!'
                            return false
                        }
                        break;
                    case 'price':
                        if(!value){
                            err_msg.value = 'עליך להזין מחיר!'
                            return false
                        }
                        break;
                }
            }

            return true
        }
        const handle_submit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                const exist = await check_if_item_exist(values.value.barcode)
                if(exist){
                    slide_pop_error(`המוצר ${values.value.barcode} קיים כבר במערכת!`)
                    is_pending.value = false
                    return 
                }
                await create_new_sign(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `המוצר ${values.value.barcode} הוסף בהצלחה`)
                .then(()=>{
                    emit('finish',values.value)
                })
            }
        }

        const handle_submit_edit = async() => {
             if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                await create_new_sign(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `המוצר ${values.value.barcode} עודכן בהצלחה`)
                .then(()=>{
                    emit('finish_edit',values.value)
                })
            }
        }

        if(props.edit_item){
            props.edit_item.user_update = user.value.uid
            props.edit_item.date_updated = new Date()
            values.value = props.edit_item
        }

        const init = async() => {
            departments.value = await get_all_departments()
        }

        init()
        return{
            handle_submit,
            handle_submit_edit,
            values,
            err_msg,
            is_pending,
            size_options,
            departments,
        }
    }
}
</script>

<style scoped>
    .create-new-sign{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        color: #333;
    }
    .create-new-sign-form{
        width: 100%;
        max-width: 500px;
        background: #fff;
        border-radius: 10px;
    }
    .create-new-sign-form-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }

    .create-new-sign-form-content{
        width: 100%;
        padding: 5px;
    }

    .create-new-sign-form-content-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>